import dynamic from 'next/dynamic'
import { ComponentType } from 'react'
import { AllComponentType } from './types'

// Here we can register new components.
// Every component should be loaded with dynamic import to optimize bundle size.
const componentRegistry: {
  // eslint-disable-next-line no-unused-vars
  [__component in AllComponentType]?: ComponentType<any>
} = {
  'commerce.product': dynamic(() => import('components/Product')),
  'commerce.category': dynamic(() => import('components/commerce/CategoryPage')),
  'cms.banner-section': dynamic(() => import('components/cms/Banner')),
  'list.list-section': dynamic(() => import('components/list')),
  'cms.feature-alternating-section': dynamic(() => import('components/cms/AlternatingFeatures')),
  'cms.gallery-section': dynamic(() => import('components/cms/Gallery')),
  'cms.text-section': dynamic(() => import('components/cms/Text')),
  'cms.text-header-section': dynamic(() => import('components/cms/TextHeader')),
  'cms.block-quote-section': dynamic(() => import('components/cms/BlockQuote')),
  'cms.columns-section': dynamic(() => import('components/cms/Columns')),
  'cms.feature-offset-section': dynamic(() => import('components/cms/FeatureOffset')),
  'cms.visual-specs-section': dynamic(() => import('components/cms/VisualSpecs')),
  'product.sectionincentives': dynamic(() => import('components/cms/Incentives')),
  'cms.download-section': dynamic(() => import('components/cms/Download')),
  'cms.leave-review-section': dynamic(() => import('components/cms/LeaveReviewSection')),
  'cms.anchor': dynamic(() => import('components/cms/Anchor')),
  'cms.london-dynamics-configurator': dynamic(() => import('components/cms/LondonDynamicsConfigurator')),
  'cms.video-section': dynamic(() => import('components/cms/Video')),
}

export default componentRegistry
