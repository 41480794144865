import type { Page } from 'framework/strapi/types'
import type { GetStaticProps } from 'next'
import { getLocaleOrDefault, localeNotFound } from 'utils/localization'
import DynamicZone from 'components/DynamicZone'
import { getPageFromCacheOrServer } from 'framework/common/server/page'
import { useRouter } from 'next/router'
import {
  getLayoutProps,
  LayoutProps,
} from 'framework/common/server/getLayoutProps'

interface Props extends LayoutProps {
  page: Page
}

export const getStaticProps: GetStaticProps<Props> = async ({
  locale: localeOrUndefined,
  preview,
}) => {
  if (localeNotFound(localeOrUndefined)) {
    return {
      notFound: true,
    }
  }
  const locale = getLocaleOrDefault(localeOrUndefined)
  const page = await getPageFromCacheOrServer('index', locale, preview)

  if (!page)
    return {
      notFound: true,
    }

  const { floatingNavigation, navigationColorTheme } = page
  const layoutProps = await getLayoutProps({
    preview,
    locale,
    floatingNavigation,
    navigationColorTheme,
  })
  // console.log('Rendering startpage ' + locale)
  return {
    props: {
      page,
      ...layoutProps,
    },
  }
}

const Index = ({ page, floatingNavigation }: Props) => {
  const router = useRouter()
  return (
    <>
      {page.main && (
        <DynamicZone
          components={page.main}
          preview={router.isPreview}
          floatingNavigation={floatingNavigation}
        />
      )}
    </>
  )
}

export default Index
