import { StrapiSection } from 'framework/common/types'
import { CmsBannerSection, StrapiComponent } from 'framework/strapi/types'
import backgroundIsCovered from './backgroundIsCovered'

// Checks if section is covered to the edges with layers, returns boolean
const sectionIsCovered = (
  locale: string,
  sectionData: Omit<StrapiComponent, '__component'>,
) => {
  if (backgroundIsCovered(locale, (sectionData as StrapiSection).background)) {
    return true
  }
  const tiles = (sectionData as CmsBannerSection).tiles
  if (!tiles?.length) return false
  return tiles.some((t) => backgroundIsCovered(locale, t.background))
}

export default sectionIsCovered
