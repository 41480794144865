import {
  AllComponentType,
  DynamicComponentProps,
  PartialProduct,
  SectionLayoutSettings,
  SectionSettingsHeightStyles,
  StrapiSection,
} from 'framework/common/types'
import { CommonSectionSettingsHeightEnum } from 'framework/strapi/types'
import React, { ComponentType, FC, useState } from 'react'
import { useAppSelector } from 'store'
import { isComponentPublishedInChannel } from 'utils/isInChannel'
import sectionIsCovered from 'utils/sectionIsCovered'
import componentRegistry from '../framework/common/component-registry'
import Background from './common/Background'
import Section from './common/Section'
// import Preview from 'components/Checkout/OrderSummary/Upsell/Preview'

interface Props {
  components: DynamicComponentProps[]
  preview: boolean
  floatingNavigation: boolean
}

const calculateHeight = (
  height: CommonSectionSettingsHeightEnum,
  index: number,
  floatingNavigation: boolean,
): SectionSettingsHeightStyles => {
  if (height === 'Screen' && index === 0) {
    return floatingNavigation ? 'ScreenTopFloatingNav' : 'ScreenTop'
  }
  return height
}

const DynamicZone: FC<Props> = ({
  components,
  preview,
  floatingNavigation,
}) => {
  let prevSectionIsCovered: boolean | null = null
  const { locale } = useAppSelector((s) => s.channel)
  const [, setShowPreview] = useState(false)
  const [, setSelectedProduct] = useState<PartialProduct | null>(null)

  return (
    <>
      {/*showPreview && selectedProduct && <Preview product={selectedProduct} />*/}
      {components.map((c, i) => {
        if (!isComponentPublishedInChannel(locale, c)) return null
        const isCovered = sectionIsCovered(locale, c)
        const section = c as StrapiSection
        const isFirst = i === 0
        const isLast = i === components.length - 1
        const hasMarginTop = !isFirst && (!prevSectionIsCovered || !isCovered)
        const hasMarginBottom = isLast && !isCovered
        const sectionSettings: SectionLayoutSettings = {
          preview,
          height: calculateHeight(
            section.settings?.height || 'Default',
            i,
            floatingNavigation,
          ),
          hasMarginTop,
          hasMarginBottom,
          isCovered,
          background: section.background!,
        }

        prevSectionIsCovered = isCovered

        const dynamicComponentProps = {
          ...c,
          sectionSettings: sectionSettings,
        }

        if (c.__component === 'list.list-section') {
          dynamicComponentProps.setShowPreview = setShowPreview
          dynamicComponentProps.setSelectedProduct = setSelectedProduct
        }

        if (section.settings) {
          return (
            <Section
              key={c.__component + c.id}
              {...section.settings}
              layoutSettings={sectionSettings}
            >
              {section.background && <Background {...section.background} />}
              <DynamicComponent {...dynamicComponentProps} />
            </Section>
          )
        }
        return (
          <DynamicComponent
            key={c.__component + c.id}
            {...dynamicComponentProps}
          />
        )
      })}
    </>
  )
}

export const DynamicComponent = (
  c: DynamicComponentProps & { sectionSettings: SectionLayoutSettings },
) => {
  const Component = createComponent(c.__component)
  if (Component == null) return null
  return <Component {...c} />
}

export const createComponent = <T extends DynamicComponentProps>(
  componentName: AllComponentType,
) => {
  // console.log('Rendering component', componentName)
  const component = componentRegistry[componentName] as ComponentType<T>
  if (!component) {
    console.warn(
      `Could not find any React component matching: ${componentName}. Have you added it to the component.registry?`,
    )
    return null
  }
  return component
}

export default DynamicZone
